/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var isMobile = {
              Android: function() {
                return navigator.userAgent.match(/Android/i);
              },
              BlackBerry: function() {
                return navigator.userAgent.match(/BlackBerry/i);
              },
              iOS: function() {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
              },
              Opera: function() {
                return navigator.userAgent.match(/Opera Mini/i);
              },
              Windows: function() {
                return navigator.userAgent.match(/IEMobile/i);
              },
              any: function() {
                return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
              }
          };

        // Parallax
        var parallax = function() {
            $(window).stellar();
        };

        // Mobile outside click close offcanvas
        var mobileMenuOutsideClick = function() {

          $(document).click(function (e) {
          var container = $("#offcanvas, .js-nav-toggle");
          if (!container.is(e.target) && container.has(e.target).length === 0) {

              if ( $('body').hasClass('offcanvas') ) {

                  $('body').removeClass('offcanvas');
                  $('.js-nav-toggle').removeClass('active');
              }
          }
          });

      };

       // Offcanvas menu
       var offcanvasMenu = function() {

            $('#page').prepend('<a href="#" class="js-nav-toggle nav-toggle"><i></i></a>');
            $('#offcanvas .menu-item-has-children').addClass('offcanvas-has-dropdown');
            $('#offcanvas')
              .find('li')
              .removeClass('menu-item-has-children');

            // Hover dropdown menu on mobile
            $('.offcanvas-has-dropdown').mouseenter(function(){

            $(this)
              .addClass('active')
              .find('ul')
              .slideDown(500, 'easeOutExpo');
            }).mouseleave(function(){

            $(this)
              .removeClass('active')
              .find('ul')
              .slideUp(500, 'easeOutExpo');
            });

            $(window).resize(function(){
                if ( $('body').hasClass('offcanvas') ) {
                  $('body').removeClass('offcanvas');
                  $('.js-nav-toggle').removeClass('active');
                }
            });
        };

        var burgerMenu = function() {

            $('body').on('click', '.js-nav-toggle', function(event){
                var $this = $(this);
                if ( $('body').hasClass('offcanvas') ) {
                    $('body').removeClass('offcanvas');
                } else {
                    $('body').addClass('offcanvas');
                }
                $this.toggleClass('active');
                event.preventDefault();

            });
        };

        var goToTop = function() {
            $('.js-gotop').on('click', function(event){
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $('html').offset().top
                }, 500);
                return false;
            });
        };

         // Loading page
        var loaderPage = function() {
            $(".loader").fadeOut("slow");
        };

        // Window Scroll
        var windowScroll = function() {
            var lastScrollTop = 0;

            $(window).scroll(function(event){

                var header = $('#header'),
                    scrlTop = $(this).scrollTop();

                if ( scrlTop > 500 && scrlTop <= 2000 ) {
                    header.addClass('navbar-fixed-top animated slideInDown');
                } else if ( scrlTop <= 500) {
                    if ( header.hasClass('navbar-fixed-top') ) {
                        header.addClass('navbar-fixed-top animated slideOutUp');
                        setTimeout(function(){
                            header.removeClass('navbar-fixed-top animated slideInDown slideOutUp');
                        }, 100 );
                    }
                }

            });
        };

      var initFlexSlider = function() {
        $('.flexslider').flexslider({
        });
      };

      var initFrontCarousel = function() {
        var frontCarousel = $('.front-carousel');
        frontCarousel.owlCarousel({
          loop: true,
          autoplay: 3,
          margin: 5,
          dots: false,
          nav: true,
          navText: ["<i class='ion-ios-arrow-left'>", "<i class='ion-ios-arrow-right'>"],
          items: 1,
        });
     };

     var initVehicleCarousel = function() {
        var vehicleCarousel = $('.vehicle-carousel');
        vehicleCarousel.owlCarousel({
          loop: true,
          autoplay: 3,
          margin: 15,
          dots: true,
          nav: false,
          navText: ["<i class='ion-ios-arrow-left'>", "<i class='ion-ios-arrow-right'>"],
          items: 1,
          responsive : {
            768 : {
                items: 2
            },
            992 : {
                items: 4
            }
        }
        });
    };

    var initFlexsliderCarousel = function() {
      $('#carousel').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        itemWidth: 160,
        itemMargin: 5,
        asNavFor: '#slider'
      });

      var $slider = $('#slider');
      $slider.flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        sync: "#carousel",
        start: function () {
          // Prevent magnificPopup from opening when a nav arrow is clicked
          var $sliderNavArrows = $slider.find('.flex-prev, .flex-next');
          $sliderNavArrows.click(function (e) {
            e.stopPropagation();
          });
        },
      });
    };

    var initMagnificPopup = function() {
      $('#slider').each(function() { // the containers for all your galleries
          $(this).magnificPopup({
              delegate: 'a.vehicle-gallery-image', // the selector for gallery item
              type: 'image',
              gallery: {
                enabled:true,
                arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"><i class="ion-chevron-%dir%"></i></button>'
              }
          });
      });
    };


      // initMap
      if (typeof ag_initMap === 'function') {
            ag_initMap($);
      }

        // Masonry grid
        var initMasonry = function () {
          var $grid = $('.masonry-grid');

          if ($grid.length) {
            $grid.masonry({
              itemSelector: '.masonry-column',
              columnWidth: '.masonry-column-sizer',
              percentPosition: true
            });

            $grid.imagesLoaded().progress(function () {
              $grid.masonry('layout');
            });
          }
        };

      // Document on load.
      $(function(){
          parallax();
          mobileMenuOutsideClick();
          initFrontCarousel();
          initVehicleCarousel();
          offcanvasMenu();
          burgerMenu();
          windowScroll();
          goToTop();
          initFlexsliderCarousel();
          initMagnificPopup();
          initMasonry();
          loaderPage();
          Sage.common.initNotificationModal();
      });


      },
      initNotificationModal: function () {
        var $notificationModal = $('#notification-modal');

        if ($notificationModal.length) {
          var wasNotificationModalClosed = window.sessionStorage.getItem('bonny_notification_modal_closed');

          if (!wasNotificationModalClosed) {
            $notificationModal.modal('show');
            $notificationModal.on('hide.bs.modal', function () {
              window.sessionStorage.setItem('bonny_notification_modal_closed', true);
            });
          }
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Blog page
    'blog': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'home': {
      init: function() {
        // JavaScript to be fired on the about us page

        var $owlCarousel = $('#hero .owl-carousel').owlCarousel({
          items: 1,
          loop: true,
          nav: true,
          autoplay: 4000,
        });



      var initServiceGrid = function() {
        var $grid = $('.service-grid').masonry({
            itemSelector: '.banner',
            columnWidth: '.banner-column-sizer',
            percentPosition: true
          });

        $grid.imagesLoaded().progress( function() {
          $grid.masonry('layout');
        });
      };

      // Document on load.
      $(function(){
          initServiceGrid();
      });

      }
    },
    'post_type_archive_vehicle': {
      init: function () {
        this.initAttributes();
        this.registerPanelEvents();
        this.registerChangeEvents();
        this.handlePaginationLinks();
        this.collapseInitialPanel();

        var $priceMinMaxInput = $('input[name="price_min_max"]');
        $priceMinMaxInput.slider({
          tooltip: 'hide',
        });

        $('#vehicle-filter-collapse-price').on('shown.bs.collapse', function (e) {
          $priceMinMaxInput.slider('relayout');
        });
      },
      initAttributes: function () {
        var self = this;

        self.$vehicleFilterContainer = $('#vehicle-filter-container');
        self.$vehicleFilterForm = self.$vehicleFilterContainer.find('#vehicle-filter-form');
        self.$vehicleFilterFoundCount = self.$vehicleFilterContainer.find('#vehicle-filter-found-count');
        self.$vehicleFilterPanelHeadings = self.$vehicleFilterContainer.find('.panel-heading');
        self.$vehicleFilterPanelCollapses = self.$vehicleFilterContainer.find('.panel-collapse');
        self.$vehicleFilterMinPriceTitle = self.$vehicleFilterContainer.find('#vehicle-filter-title-min-price');
        self.$vehicleFilterMaxPriceTitle = self.$vehicleFilterContainer.find('#vehicle-filter-title-max-price');
        self.$vehicleFilterPriceInput = self.$vehicleFilterContainer.find('#vehicle-filter-value-price');
        self.$vehicleFilterYearTitle = self.$vehicleFilterContainer.find('#vehicle-filter-title-year');
        self.$vehicleFilterYearInput = self.$vehicleFilterContainer.find('#vehicle-filter-value-year');
        self.$vehicleFilterSortTitle = self.$vehicleFilterContainer.find('#vehicle-filter-title-sort');
        self.$vehicleFilterSortBtnGroup = self.$vehicleFilterContainer.find('#vehicle-filter-value-sort');
        self.$vehicleFilterOrderBtnGroup = self.$vehicleFilterContainer.find('#vehicle-filter-value-order');
        self.$vehicleList = $('#vehicle-list');
      },
      registerPanelEvents: function() {
        var self = this;

        $('#vehicle-filter-heading-model').click(function (e) {
          var currentBrand = self.getCurrentBrand();

          if (currentBrand) {
            var selector = self.modelCollapseBaseId + '-' + currentBrand;
            var $collapse = $(selector);

            if ($collapse.hasClass('in')) {
              $collapse.collapse('hide');
            } else {
              $collapse.collapse('show');
            }
          }
        });

        self.$vehicleFilterPanelHeadings.each(function (i, panelHeading) {
          var $panelHeading = $(panelHeading);
          var defaultHeadingTitle = self.getPanelHeadingSelector($panelHeading).html();

          // Save default panel title as data attribute
          $panelHeading.data(self.defaultHeadingTitleDataKey, defaultHeadingTitle);

          // Toggle panel collapse when the panel title (not the anchor) is clicked
          $panelHeading.find('.panel-title').click(function(e) {
            if (!$panelHeading.hasClass('vehicle-filter-disabled') && $panelHeading.attr('id') !== 'vehicle-filter-heading-model') {
              $panelHeading.siblings('.panel-collapse').collapse('toggle');
            }
          });
        });

        self.$vehicleFilterPanelCollapses.each(function (i, panelCollapse) {
          var $panelCollapse = $(panelCollapse);
          var $panelHeading = $panelCollapse.siblings('.panel-heading');

          $panelCollapse.on('show.bs.collapse', function (e) {
            // Close other panels when a panel is about to be shown
            self.$vehicleFilterPanelCollapses.filter('.in').each(function (e) {
              $(this).collapse('hide');
            });

            // Change icon
            $panelHeading.find('i').removeClass().addClass(self.iconUpClass);
          });

          $panelCollapse.on('hide.bs.collapse', function (e) {
            // Change icon
            $panelHeading.find('i').removeClass().addClass(self.iconDownClass);
          });
        });
      },
      // Update panel title when input changes
      registerChangeEvents: function() {
        var self = this;

        self.$vehicleFilterForm.find('input').change(function() {
          self.debounce(function () {
            self.loadPosts(1);
          }, 500);
        });

        self.$vehicleFilterPriceInput.on('change', function (e) {
          var val = self.$vehicleFilterPriceInput.slider('getValue');
          // val[0] is the minimum value, val[1] the maximum
          self.$vehicleFilterMinPriceTitle.text(val[0]);
          self.$vehicleFilterMaxPriceTitle.text(val[1]);
        });

        self.$vehicleFilterYearInput.on('change', function (e) {
          var min = self.$vehicleFilterYearInput.attr('min');
          var max = self.$vehicleFilterYearInput.attr('max');
          var val = parseInt(self.$vehicleFilterYearInput.val());

          if (min <= val && val <= max) {
            self.$vehicleFilterYearTitle.text(val);
          }
        });

        self.$vehicleFilterSortBtnGroup.on('change', 'input:checked', function (e) {
          var $sortInput = $(this);
          var $orderInput = self.$vehicleFilterOrderBtnGroup.find('input:checked');
          var $sortName = $sortInput.siblings('.vehicle-filter-value');

          // If no order input has been checked, let's check the first one
          if ($orderInput.length === 0) {
            $orderInput = self.$vehicleFilterOrderBtnGroup.find('input').first();
            $orderInput.prop('checked', true).closest('.btn').addClass('active');
          }

          var orderText = $sortName.text() + ' ' + $orderInput.val() + '.';

          self.$vehicleFilterSortTitle.text(orderText);
        });

        self.$vehicleFilterOrderBtnGroup.on('change', 'input:checked', function (e) {
          var $orderInput = $(this);
          var $sortInput = self.$vehicleFilterSortBtnGroup.find('input:checked');

          // If no sort input has been checked, let's check the first one
          if ($sortInput.length === 0) {
            $sortInput = self.$vehicleFilterSortBtnGroup.find('input').first();
            $sortInput.prop('checked', true).closest('.btn').addClass('active');
          }

          var $sortName = $sortInput.siblings('.vehicle-filter-value');
          var orderText = $sortName.text() + ' ' + $orderInput.val() + '.';

          self.$vehicleFilterSortTitle.text(orderText);
        });
      },
      handlePaginationLinks: function() {
        var self = this;

        self.$vehicleList.find('.pagination a').click(function (e) {
          e.preventDefault();

          var page = 1;
          var $anchor = $(this);
          var href = $anchor.attr('href');
          var match = href.match(/page\/(\d+)/);

          if (match) {
            page = parseInt(match[1]);
          }

          self.debounce(function () {
            self.loadPosts(page);
          });
        });
      },
      collapseInitialPanel: function () {
        var self = this;
        var $brandPanels = self.$vehicleFilterContainer.find('.vehicle-filter-is-brand');
        var isBrandSet = true;
        var isModelSet = false;

        // Handle brand/model collapse
        $brandPanels.each(function (i, panel) {
          var $panel = $(panel);
          var $input = $panel.find('input');
          var $checkedInput = $panel.find('input:checked');

          $panel.siblings('.panel-heading').click(function (e) {
            // the brand or model panel had a checked value, let's uncheck it
            if ($(this).hasClass('vehicle-filter-set')) {
              // The brand model has been unset (index 0), so let's unset the model too
              if (i === 0) {
                self.disableModelCollapse();
                self.unsetButtonGroup(self.$vehicleFilterPanelCollapses);
                self.changePanelHeading(self.$vehicleFilterPanelHeadings.eq(0));
                self.changePanelHeading(self.$vehicleFilterPanelHeadings.eq(1));
              } else {
                self.unsetButtonGroup(self.$vehicleFilterPanelCollapses.slice(1));
                self.changePanelHeading(self.$vehicleFilterPanelHeadings.eq(1));
              }
            }
          });

          // A brand or model input has been checked, let's close the current panel and collapse the following panel
          $input.change(function (e) {
            if (i === 0) {
              var brand = $(this).val();
              self.enableModelCollapse(brand, true);
            }

            $panel.collapse('hide');
            self.changePanelHeading($panel.siblings('.panel-heading'), $(this).siblings('.vehicle-filter-value').text());

            if (i !== 0) {
              self.$vehicleFilterPanelCollapses.eq($brandPanels.length).collapse('show');
            }
          });

          // This code is only executed once
          // it opens the first panel that has no checked value
          if (i === 0) {
            if ($checkedInput.length === 0) {
              $panel.collapse();
              isBrandSet = false;
              self.disableModelCollapse();
            } else {
              self.changePanelHeading($panel.siblings('.panel-heading'), $checkedInput.siblings('.vehicle-filter-value').text());
            }
          } else {
            if (!isModelSet && $checkedInput.length) {
              isModelSet = true;
              self.changePanelHeading($panel.siblings('.panel-heading'), $checkedInput.siblings('.vehicle-filter-value').text());
            }
          }
        });

        // Brand values (brand and model) are already set
        // let's collapse the next panel
        if (isBrandSet && isModelSet) {
          self.$vehicleFilterPanelCollapses.eq($brandPanels.length).collapse();
        } else if(isBrandSet) {
          self.enableModelCollapse(self.getCurrentBrand(), true);
        }
      },
      unsetButtonGroup: function ($panelCollapses) {
        var self = this;

        $panelCollapses.each(function (i, e) {
          var $checkedInput = $(this).find('input:checked');

          $checkedInput.prop('checked', false);
          $checkedInput.closest('.btn').removeClass('active');
        });

        self.debounce(function() {
          self.loadPosts(1);
        }, 500);
      },
      enableModelCollapse: function(model, collapse) {
        var href = this.modelCollapseBaseId + '-' + model;
        collapse = collapse || false;

        this.$vehicleFilterPanelHeadings.eq(1).removeClass('vehicle-filter-disabled').find('a');

        if (collapse) {
          this.$vehicleFilterPanelCollapses.filter(href).collapse('show');
        }
      },
      disableModelCollapse: function () {
        this.$vehicleFilterPanelHeadings.eq(1).addClass('vehicle-filter-disabled');
      },
      getPanelHeadingSelector: function ($panel) {
        return $panel.find('.vehicle-filter-title');
      },
      getCurrentBrand: function () {
        var $vehicleBrand = $('#vehicle-filter-collapse-brand');

        return $vehicleBrand.find('input:checked').val();
      },
      getCurrentModel: function () {
        var $vehicleModel = $(this.modelCollapseBaseId + '-' + this.getCurrentBrand());

        return $vehicleModel.find('input:checked').val();
      },
      // newHeading param is optional, if no value is given, then the default panel title will be used
      changePanelHeading: function ($panelHeading, newHeading) {
        var self = this;
        var $panelHeadingTitle = this.getPanelHeadingSelector($panelHeading);

        if (newHeading) {
          $panelHeadingTitle.text(newHeading);
          $panelHeadingTitle.siblings('i').removeClass().addClass(self.iconRemoveClass);
          $panelHeading.addClass('vehicle-filter-set');
        } else {
          var defaultHeadingTitle = $panelHeading.data(self.defaultHeadingTitleDataKey);

          $panelHeadingTitle.text(defaultHeadingTitle);
          $panelHeadingTitle.siblings('i').removeClass().addClass(self.iconDownClass);
          $panelHeading.removeClass('vehicle-filter-set');
        }
      },
      setFoundVehicleCount: function(foundCount) {
        var text = '';

        if (foundCount !== 0) {
          text = wp.i18n._n('Véhicule', 'Véhicules', foundCount, 'bonny');
        } else {
          text = wp.i18n.__('Véhicule', 'bonny');
        }

        this.$vehicleFilterFoundCount.text(foundCount + ' ' + text);
      },
      serializeObject: function(array) {
        var serializedObject = {};

        for (var i = 0; i < array.length; i++) {
          var name = array[i].name;
          var value = array[i].value;

          if (value) {
            serializedObject[name] = value;
          }
        }

        return serializedObject;
      },
      debounceTimer: null,
      debounce: function (fn, delay) {
        var self = this;
        var args = arguments;

        self.$vehicleList.fadeOut();

        clearTimeout(self.debounceTimer);
        self.debounceTimer = setTimeout(function () {
          fn.apply(self, args);
        }, delay);
      },
      loadPosts: function (page) {
        var self = this;
        var url = '/wp/wp-admin/admin-ajax.php';
        var data = this.serializeObject(this.$vehicleFilterForm.serializeArray());

        page = page || 1;

        if (self.currentAjaxRequest) {
          self.currentAjaxRequest.abort();
        }

        self.currentAjaxRequest = $.get(url,
          $.extend({ action: 'vehicle_filter', paged: page }, data),
          function (response) {
            if (response.success && response.data) {
              self.loadPostsCallback(response.data);
            }
          })
          .complete(function(jqXHR, status) {
            if (status !== 'abort') {
              self.currentAjaxRequest = null;
            }
          });
      },
      loadPostsCallback: function(data) {
        var self = this;

        if (data.count || data.count === 0) {
          this.setFoundVehicleCount(data.count);
        }

        if (data.content) {
          var $html = $(data.content);
          self.$vehicleList.html($html.html());

          self.$vehicleList.fadeIn(function () {
            Sage.common.contentWayPoint(self.$vehicleList);
            self.handlePaginationLinks();
          });
        }

        if (window.history && data.url) {
          var href = data.url.href || window.location.href;
          var title = data.url.title || document.title;

          if (window.location.href !== href) {
            document.title = title;
            history.pushState(null, null, href);
          }
        }
      },
      iconDownClass: 'glyphicon glyphicon-chevron-down',
      iconUpClass: 'glyphicon glyphicon-chevron-up',
      iconRemoveClass: 'glyphicon glyphicon-remove',
      defaultHeadingTitleDataKey: 'defaultHeadingTitle',
      modelCollapseBaseId: '#vehicle-filter-collapse-model',
    },
    currentAjaxRequest: null,
    tax_vehicle_state: {
      init: function () {
        Sage.post_type_archive_vehicle.init();
      },
    },
    tax_vehicle_brand: {
      init: function () {
        Sage.post_type_archive_vehicle.init();
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
